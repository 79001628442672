import React, { useState, useEffect } from 'react';  // <-- Add useEffect here

import Papa from 'papaparse';

import logo from '../assets/uniforlogo.jpg'; // Replace with the path to the Local's logo

function PicketSchedule() {
  const [masterNumber, setMasterNumber] = useState("");
  const [result, setResult] = useState(null);
  const [searched, setSearched] = useState(false);  // Track if a search has been performed
  const [csvData, setCsvData] = useState([]);       // State to store the parsed CSV data

  useEffect(() => {
    // Load and parse the CSV data when the component mounts
    Papa.parse('../data/data.csv', {
      header: true,  // This will make sure the first row is interpreted as the header and each row is parsed into an object
      download: true,
      complete: (results) => {
        console.log("Parsed results:", results.data);
        setCsvData(results.data);
      }
    });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!masterNumber || masterNumber.trim() === "") {
      alert("Please enter a valid Master #.");
      return; // Exit the function early
    }
    
    const foundPerson = csvData.find(person => person['Master #:'].includes(masterNumber.trim()));
    setResult(foundPerson);
    setSearched(true);
  }
  

  return  (
    <div className="p-4 bg-gray-100 min-h-screen flex flex-col items-center">
      <img src={logo} alt="Unifor 1285 Logo" className="mb-6 w-32 h-32 rounded-full" />
      
      <h1 className="text-2xl mb-4 text-center">Unifor 1285 Brampton Assembly Plant Picket Schedule</h1>
      
      <p className="text-center mb-4 leading-relaxed">
  In the event of a Strike, the plant will be picketed <strong>24 hours a day, 7 days a week</strong> until the strike is settled. Picket lines have been assigned in <strong>SIX-HOUR shifts</strong> based on seniority. Each member will be required to picket on <strong>TWO separate shifts</strong> each week. We have given Unifor Local 1285 members the opportunity to provide input on their two most preferred shifts.
</p>

<p className="text-center mb-4 leading-relaxed bg-red-200 p-3 rounded">
  If you did not have the seniority to be accommodated on your choices or failed to submit a form, you were assigned a shift.
</p>

<p className="text-center mb-4 leading-relaxed">
  Please note this does <strong>NOT</strong> mean the local will be going on strike. The Strike Chair will communicate with all members when and if Strike Action is required. If you have not done so already, please sign-up on the local’s website <a href="http://www.uniforlocal1285.ca" target="_blank" rel="noopener noreferrer" className="underline text-blue-600">www.uniforlocal1285.ca</a> to stay up to date on the latest information regarding our next steps.
</p>

<p className="text-center mb-4 leading-relaxed">
  Use the search bar below to find your shift, enter in your Master # - <strong>5 characters only</strong>.
</p>

      {/* Search bar */}
      <form onSubmit={handleSearch} className="mb-4 flex max-w-md mx-auto">
        <input 
          type="text" 
          value={masterNumber} 
          onChange={e => setMasterNumber(e.target.value)}
          className="border-2 rounded-l px-3 py-2 w-full"
          maxLength="5"
          placeholder="Enter Master #"
        />
        <button 
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-r hover:bg-blue-700 active:bg-blue-800"
        >
          Search
        </button>
      </form>

      {/* Search results */}
      {searched && (
        <div className="bg-white p-6 rounded shadow-md max-w-md w-full">
          {result ? (
            <>
              <p><strong>Name:</strong> {result["Full Name"]}</p>
              <p><strong>Shift One:</strong> {result["Shift #1"]}</p>
              <p><strong>Shift Two:</strong> {result["Shift #2"]}</p>
              <p><strong>Gate:</strong> {result["Gate"]}</p>
              {/* <p><strong>Strike Captain:</strong> {result["Strike Captain?"]}</p>
              {result["Strike Captain?"] === "TRUE" && (
                <p className="bg-yellow-200 text-yellow-800 font-bold p-4 rounded-lg shadow-md">
                You have been selected as a Strike Captain, the Strike Chair will be in contact with you.
              </p>
              
              )} */}
            </>
          ) : (
            <p>
              Master number not found. Please try again, if you are having difficulties accessing your Strike schedule, please contact <a href="mailto:strike@uniforlocal1285.ca">strike@uniforlocal1285.ca</a>
            </p>
          )}
        </div>
      )}

      
    </div>
  );
}

export default PicketSchedule;
